import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Button from "../../components/Button";
import Layout from "../../components/Layout";
import Section from "../../components/Section";
import { Link } from "gatsby";
import TitleBar from "../../components/TitleBar";
import Headline from "../../components/Headline";
import Ctas from "../../components/Ctas";
import TableOfContents from "../../components/TableOfContents";
import TableOfContentsItem from "../../components/TableOfContentsItem";
import SupportLink from "../../components/SupportLink";
import Feature from "../../components/Feature";
import WorksWith from "../../components/WorksWith";
import Issue from "../../components/Issue";
import Callout from "../../components/Callout";
import { ImportSlackThreads } from "../../components/Icons";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import Screenshot from '../../images/import-slack-threads/screenshot.png'
import FooterCta from "../../components/FooterCta";

const Step = ({ step, subtitle, image }) => {
  return (
    <li className="pb-4 last:pb-0 md:pb-8 m-0">
      <h5 className="opacity-50 m-0">Step {step}</h5>
      <h4 className="text-2xl font-bold text-black mb-5">
        {subtitle}
      </h4>
      <div className="max-w-prose -mx-3">{image}</div>
    </li>
  );
};

const ToolkitQna = () => {
  return (
    <Layout title="Import Slack threads and display them on your docs or website" description="Rescue valuable knowledge before it disappears into the abyss forever.">
      <TitleBar title="Import Slack Threads" toolkit icon={<ImportSlackThreads />}>
        <div className="hidden sm:flex">
          <Button url="https://squeak.cloud/signup">
              Try Squeak! Cloud
          </Button>
        </div>
      </TitleBar>
      
      <article className="">
        <Section id="overview">
          <Headline
            h1="Embed community Slack threads on your website"
            subtitle="Rescue valuable knowledge from Slack before it disappears into the abyss forever."
          />

          <p className="mb-8">Embed community threads on specific pages of your docs or website with <Link href="/toolkit/qna.js">Q&amp;A.js</Link> - specifically designed for large Slack communities where user volume makes it cost-prohibitive to use a paid plan (with unlimited message history).</p>

          <Ctas>
            <Button url="https://squeak.cloud/signup">Try Squeak! Cloud</Button>

            {/*
            <div className="relative">
              <Button
                url="#demo"
                type="secondary"
                className="w-full"
              >
                Demo
              </Button>
              <TrySqueak className="absolute top-[140%] sm:top-[105%] pt-1 left-[35%] sm:left-[75%] rotate-3 sm:-rotate-6" />
            </div>
            */}
          </Ctas>
        </Section>
      
        <TableOfContents>
            <TableOfContentsItem name="Screenshot" url="#screenshots" />
            <TableOfContentsItem name="Features" url="#features" />
            <TableOfContentsItem name="How it works" url="#how-it-works" />
            <TableOfContentsItem name="Demo" url="#demo" />
            <SupportLink name="Questions?" url="/questions" />
        </TableOfContents>

        <Section id="screenshots" className="my-0">
          <Zoom>
            <img src={Screenshot} className="max-w-3xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl -mx-6 w-[calc(100%+3rem)] xl:-mx-10 xl:w-[calc(100%+5rem)] 2xl:-mx-12 2xl:w-[calc(100%+6rem)] transition-all duration-300" alt="Import Slack Threads by Squeak!"/>
          </Zoom>
        </Section>

        <Section id="features">
            <h3>Features</h3>

            <h4 className="text-black text-opacity-60 text-lg pb-2">Available now</h4>
            <ul className="text-base mb-8">
              <Feature name="Import conversations from the latest 50 threads from a specified Slack channel" />
              <Feature name="Set a subject to summarize the thread" />
            </ul>

            <h4 className="text-black text-opacity-60 text-lg pb-2">Toolkit integrations</h4>
            <ul className="text-base mb-8">
              <WorksWith toolkitItem="Q&A.js" to="Choose a URL where the thread should be displayed"  url="" />
            </ul>

            <h4 className="text-black text-opacity-60 text-lg pb-2">On the roadmap</h4>
            <ul className="text-base mb-8">
              <Issue name='Privacy settings for imported posts' url="https://github.com/PostHog/squeak/issues/154" />
              <Issue name='Push specific threads to Squeak! from within Slack' url="https://github.com/PostHog/squeak/issues/59" />
              <Issue name='Auto-populate known URLs when specifying thread’s path' url="https://github.com/PostHog/squeak/issues/153" />
              <Issue name='Support multiple URLs for a thread' url="https://github.com/PostHog/squeak/issues/152" />
              <Issue name='Edit message support' url="https://github.com/PostHog/squeak/issues/151" />
              <Issue name='Choose which replies to import' url="https://github.com/PostHog/squeak/issues/155" />
            </ul>

            <Callout>
              <p>We have no plans to charge for this product.</p>
            </Callout>

        </Section>

        <Section id="how-it-works">
            <h3>How it works</h3>
              <p className="">Here's how to import a Slack thread into Squeak! for safekeeping forever.</p>
              <ol className="pt-8">
                <Step
                  step={1}
                  subtitle="Set up your source channel from Slack"
                  image={
                    <StaticImage
                      quality={100}
                      src="../../images/import-slack-threads/step-1.png"
                      alt="A user posts a question on your website or docs"
                    />
                  }
                />
                <Step
                  step={2}
                  subtitle="Visit the Import Slack threads tab in the Squeak! admin and select threads to import"
                  image={
                    <StaticImage
                      quality={100}
                      src="../../images/import-slack-threads/step-2.png"
                      alt="Moderators get notified in Slack"
                    />
                  }
                />
                <Step
                  step={3}
                  subtitle="If you’re using Q&A.js, set a subject and relative path where the question should appear on your site"
                  image={
                    <StaticImage
                      quality={100}
                      src="../../images/import-slack-threads/step-3.png"
                      alt="Moderators (and your community) can reply"
                    />
                  }
                />
              </ol>
        </Section>

        <FooterCta 
          title="Try Squeak!"
          description="It's not hard. It's just a few clicks."
        />
      </article>
    </Layout>
  );
};

export default ToolkitQna;
